<template>
  <b-card
    :key="keys.InfoList"
    header="Profile Information"
    no-body
  >
    <b-list-group flush>
      <b-list-group-item
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="emailClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_email') }}:
        </div>
        <b-input-group
          style="width: 280px"
        >
          <b-input-group-prepend is-text :title="isEmailVerified ? 'Verified' : 'Not verified'">
            <font-awesome-icon v-if="isEmailVerified" :icon="['fas', 'check']" class="text-success" />
            <font-awesome-icon v-else :icon="['fas', 'triangle-exclamation']" class="text-danger" />
          </b-input-group-prepend>
          <b-form-input
            ref="email"
            v-model="email"
            type="text"
            size="sm"
            :disabled="isLocked"
            @input="onChange('email')"
          />
          <b-input-group-append>
            <b-button
              v-if="isEmailVerified"
              variant="outline-danger"
              size="sm"
              style="width: 70px"
              title="Remove verification"
              :disabled="!email || Object.keys(dirty).includes('email')"
              @click.prevent="onUnverifyEmail"
            >
              Remove
            </b-button>
            <b-button
              v-else
              variant="outline-success"
              size="sm"
              style="width: 70px"
              title="Set verified"
              :disabled="!email || Object.keys(dirty).includes('email')"
              @click.prevent="onVerifyEmail"
            >
              Verify
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="mobileClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_mobile') }}:
        </div>
        <b-input-group
          style="width: 280px"
        >
          <b-input-group-prepend is-text :title="isMobileVerified ? 'Verified' : 'Not verified'">
            <font-awesome-icon v-if="isMobileVerified" :icon="['fas', 'check']" class="text-success" />
            <font-awesome-icon v-else :icon="['fas', 'triangle-exclamation']" class="text-danger" />
          </b-input-group-prepend>
          <b-form-input
            ref="mobile"
            v-model="mobile"
            type="tel"
            size="sm"
            :disabled="isLocked"
            @input="onChange('mobile')"
          />
          <b-input-group-append>
            <b-button
              v-if="isMobileVerified"
              variant="outline-danger"
              size="sm"
              style="width: 70px"
              title="Remove verification"
              :disabled="!mobile || Object.keys(dirty).includes('mobile')"
              @click.prevent="onUnverifyMobile"
            >
              Remove
            </b-button>
            <b-button
              v-else
              variant="outline-success"
              size="sm"
              style="width: 70px"
              title="Set verified"
              :disabled="!mobile || Object.keys(dirty).includes('mobile')"
              @click.prevent="onVerifyMobile"
            >
              Verify
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="telegramClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_telegram') }}:
        </div>
        <b-input-group
          style="width: 180px"
        >
          <b-form-input
            ref="telegram"
            v-model="telegram"
            type="text"
            size="sm"
            :disabled="isLocked"
            @input="onChange('telegram')"
          />
        </b-input-group>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="passwordClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_password') }}:
        </div>
        <b-form-input
          ref="password"
          v-model="password"
          type="text"
          size="sm"
          style="width: 180px"
          :disabled="isLocked"
          @input="onChange('password')"
        />
      </b-list-group-item>
      <b-list-group-item
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="smsClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_sms') }}:
        </div>
        <b-form-checkbox
          v-model="sms"
          size="sm"
          switch
          :disabled="isLocked"
        />
      </b-list-group-item>
      <b-list-group-item
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="cityIdClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_city') }}:
        </div>
        <auto-complete
          v-model="city_id"
          type="city"
          :idx="idx"
          :market="info.profile.market"
          size="sm"
          style="width: 180px"
          :disabled="isLocked"
          placeholder="Select city"
        />
      </b-list-group-item>
      <!--
      <b-list-group-item
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="cityIdClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_city') }}:
        </div>
        <b-form-select
          v-model="city_id"
          size="sm"
          :options="formData['city_id']"
          style="width: 180px"
          :disabled="isLocked"
        />
      </b-list-group-item>
      -->
      <b-list-group-item
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="bdayClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_birthDate') }}:
        </div>
        <b-input-group
          style="width: 180px"
        >
          <b-form-input
            ref="bday"
            v-model="bdayDisplay"
            type="text"
            size="sm"
            :disabled="isLocked"
            :state="bdayState"
            :formatter="bdayFormatter"
            @input="onChange('bday')"
          />
          <b-input-group-append>
            <b-form-datepicker
              v-model="bday"
              type="date"
              size="sm"
              style="width: 40px"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
              :disabled="isLocked"
              locale="en"
              close-button
              button-variant="outline-secondary"
              button-only
              right
              @context="onBdayContext"
            />
          </b-input-group-append>
        </b-input-group>
      </b-list-group-item>
      <b-list-group-item
        v-if="type === 'hostess'"
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="nationalityClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_nationality') }}:
        </div>
        <auto-complete
          v-model="characterHostessNationality"
          type="nationality"
          :idx="idx"
          :market="info.profile.market"
          size="sm"
          style="max-width: 300px"
          :disabled="isLocked"
          placeholder="Select nationality"
          label-not-found="No results matched"
          multiple
        />
      </b-list-group-item>
      <!--
      <b-list-group-item
        v-if="type === 'hostess'"
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="nationalityClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_nationality') }}:
        </div>
        <multiselect
          v-model="characterHostessNationality"
          :options="formData && formData.nationality"
          style="max-width: 300px"
          label-title="Select nationality"
          label-not-found="No results matched"
          label-search-placeholder="Search"
          text-prop="text"
          value-prop="value"
          :disabled="isLocked"
        />
      </b-list-group-item>
      -->
      <b-list-group-item
        v-if="type === 'hostess'"
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="languagesClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_languages') }}:
        </div>
        <auto-complete
          v-model="characterSpokenLanguages"
          type="language"
          :idx="idx"
          :market="info.profile.market"
          size="sm"
          style="max-width: 300px"
          :disabled="isLocked"
          placeholder="Select language"
          label-not-found="No results matched"
          multiple
        />
      </b-list-group-item>
      <!--
      <b-list-group-item
        v-if="type === 'hostess'"
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="languagesClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_languages') }}:
        </div>
        <multiselect
          v-model="characterSpokenLanguages"
          :options="formData && formData.languages"
          style="max-width: 300px"
          label-title="Select language"
          label-not-found="No results matched"
          label-search-placeholder="Search"
          text-prop="text"
          value-prop="value"
          :disabled="isLocked"
        />
      </b-list-group-item>
      -->
      <b-list-group-item
        v-if="type === 'hostess'"
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="servicesClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_services') }}:
        </div>
        <auto-complete
          v-model="characterServices"
          type="service"
          :idx="idx"
          :market="info.profile.market"
          size="sm"
          style="max-width: 300px"
          :disabled="isLocked"
          placeholder="Select services"
          label-not-found="No results matched"
          multiple
        />
      </b-list-group-item>
      <!--
      <b-list-group-item
        v-if="type === 'hostess'"
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="servicesClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_services') }}:
        </div>
        <multiselect
          v-model="characterServices"
          :options="formData && formData.services"
          style="max-width: 300px"
          label-title="Select services"
          label-not-found="No results matched"
          label-search-placeholder="Search"
          text-prop="text"
          value-prop="value"
          :disabled="isLocked"
        />
      </b-list-group-item>
      -->
      <b-list-group-item
        v-if="type === 'hostess'"
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="heightClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_height') }}:
        </div>
        <b-form-input
          ref="characterHeight"
          v-model="characterHeight"
          type="text"
          size="sm"
          style="width: 180px"
          :disabled="isLocked"
          @input="onChange('characterHeight')"
        />
      </b-list-group-item>
      <b-list-group-item
        v-if="type === 'hostess'"
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="sizeClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_size') }}:
        </div>
        <b-form-input
          ref="characterSize"
          v-model="characterSize"
          type="text"
          size="sm"
          style="width: 180px"
          :disabled="isLocked"
          @input="onChange('characterSize')"
        />
      </b-list-group-item>
      <b-list-group-item
        v-if="type === 'hostess'"
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="shoesClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_shoes') }}:
        </div>
        <b-form-input
          ref="characterShoesNumber"
          v-model="characterShoesNumber"
          type="text"
          size="sm"
          style="width: 180px"
          :disabled="isLocked"
          @input="onChange('characterShoesNumber')"
        />
      </b-list-group-item>
      <b-list-group-item
        v-if="type === 'hostess'"
        class="d-flex flex-row flex-nowrap align-items-center justify-content-between px-2"
        :class="transferClass"
      >
        <div class="small text-nowrap">
          {{ $t('fCustomer_info_transfer') }}:
        </div>
        <b-form-checkbox
          v-model="characterAvailableForTransfer"
          size="sm"
          switch
          :disabled="isLocked"
        />
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { mapComputedFormFields, mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'InfoList',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    repo: {
      type: String,
      required: true,
      default: ''
    },
    idx: {
      type: Number,
      default: -1
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      bdayDisplay: moment(this.bday).format('DD.MM.YYYY'),
      bdayState: true
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName'
    }),
    ...mapComputedFormFields('customerEdit', [
      'email',
      'mobile',
      'telegram',
      'password',
      'sms',
      'city_id',
      'bday',
      'characterHostessNationality',
      'characterSpokenLanguages',
      'characterServices',
      'characterHeight',
      'characterSize',
      'characterShoesNumber',
      'characterAvailableForTransfer'
    ]),
    ...mapComputedStateFields('customerEdit', [
      'info',
      'dirty',
      'type',
      'editColumnName',
      'editColumnCursor'
    ]),
    isEmailVerified () {
      return this.info.profile.email_verified_at !== null
    },
    isMobileVerified () {
      return this.info.profile.mobile_verified !== null
    },
    emailClass () {
      if (this.isDirty('email')) {
        return 'bg-warning'
      }
      return this.email && this.email !== '' ? 'bg-success' : 'bg-danger'
    },
    mobileClass () {
      if (this.isDirty('mobile')) {
        return 'bg-warning'
      }
      return this.mobile && this.mobile !== '' ? 'bg-success' : 'bg-danger'
    },
    telegramClass () {
      if (this.isDirty('telegram')) {
        return 'bg-warning'
      }
      return this.telegram && this.telegram !== '' ? 'bg-success' : 'bg-danger'
    },
    passwordClass () {
      if (this.isDirty('password')) {
        return 'bg-warning'
      }
      return this.password && this.password !== '' ? 'bg-warning' : ''
    },
    smsClass () {
      if (this.isDirty('sms')) {
        return 'bg-warning'
      }
      return this.sms && this.sms !== '' ? 'bg-success' : 'bg-danger'
    },
    cityIdClass () {
      if (this.isDirty('city_id')) {
        return 'bg-warning'
      }
      return this.city_id && this.city_id !== '' ? 'bg-success' : 'bg-danger'
    },
    bdayClass () {
      if (this.isDirty('bday')) {
        return 'bg-warning'
      }
      return this.bday && this.bday !== '' ? 'bg-success' : 'bg-danger'
    },
    nationalityClass () {
      if (this.isDirty('characterHostessNationality')) {
        return 'bg-warning'
      }
      return this.characterHostessNationality && this.characterHostessNationality.length ? 'bg-success' : 'bg-danger'
    },
    languagesClass () {
      if (this.isDirty('characterSpokenLanguages')) {
        return 'bg-warning'
      }
      return this.characterSpokenLanguages && this.characterSpokenLanguages.length ? 'bg-success' : 'bg-danger'
    },
    servicesClass () {
      if (this.isDirty('characterServices')) {
        return 'bg-warning'
      }
      return this.characterServices && this.characterServices.length ? 'bg-success' : 'bg-danger'
    },
    heightClass () {
      if (this.isDirty('characterHeight')) {
        return 'bg-warning'
      }
      return this.characterHeight && this.characterHeight !== '' ? 'bg-success' : 'bg-danger'
    },
    sizeClass () {
      if (this.isDirty('characterSize')) {
        return 'bg-warning'
      }
      return this.characterSize && this.characterSize !== '' ? 'bg-success' : 'bg-danger'
    },
    shoesClass () {
      if (this.isDirty('characterShoesNumber')) {
        return 'bg-warning'
      }
      return this.characterShoesNumber && this.characterShoesNumber !== '' ? 'bg-success' : 'bg-danger'
    },
    transferClass () {
      if (this.isDirty('characterAvailableForTransfer')) {
        return 'bg-warning'
      }
      return this.characterAvailableForTransfer && this.characterAvailableForTransfer !== '' ? 'bg-success' : 'bg-danger'
    }
  },
  watch: {},
  mounted () {
    this.setInputState()
  },
  methods: {
    ...mapActions({
      verifyEmail: 'forms/customerEdit/verifyEmail',
      unverifyEmail: 'forms/customerEdit/unverifyEmail',
      verifyMobile: 'forms/customerEdit/verifyMobile',
      unverifyMobile: 'forms/customerEdit/unverifyMobile'
    }),
    setInputState () {
      const cols = [
        'email',
        'mobile',
        'telegram',
        'password',
        'bday',
        'characterHeight',
        'characterSize',
        'characterShoesNumber'
      ]
      if (cols.includes(this.editColumnName) && this.$refs[this.editColumnName]) {
        const el = this.$refs[this.editColumnName].$el
        if (el && this.$refs[this.editColumnName].$el.setSelectionRange && this.editColumnCursor >= 0) {
          this.$nextTick(() => {
            el.focus()
            this.$nextTick(() => {
              el.setSelectionRange(this.editColumnCursor, this.editColumnCursor)
            })
          })
        } else {
          el.focus()
        }
      }
    },
    isDirty (fieldKey) {
      const dirtyFields = this.dirty && Object.keys(this.dirty)
      if (dirtyFields && dirtyFields.length && dirtyFields.includes(fieldKey)) {
        return true
      }
      return false
    },
    onChange (colName) {
      this.editColumnName = colName
      if (this.$refs[colName]) {
        const column = this.$refs[colName]
        this.editColumnCursor = (column && column.selectionStart) || -1
      }
      this.$nextTick(() => {
        this.setInputState()
      })
    },
    async onVerifyEmail () {
      await this.verifyEmail({ itemId: this.itemId, value: this.email })
      this.$emit('refresh')
    },
    async onUnverifyEmail () {
      await this.unverifyEmail({ itemId: this.itemId, value: this.email })
      this.$emit('refresh')
    },
    async onVerifyMobile () {
      await this.verifyMobile(this.itemId)
      this.$emit('refresh')
    },
    async onUnverifyMobile () {
      await this.unverifyMobile(this.itemId)
      this.$emit('refresh')
    },
    bdayFormatter (v) {
      const parsed = moment(v, 'DD.MM.YYYY')
      if (parsed.isValid() && parsed.format('DD.MM.YYYY') === v) {
        if (this.bday !== parsed.format('YYYY-MM-DD')) {
          this.bday = parsed.format('YYYY-MM-DD')
        }
        this.bdayState = true
      } else {
        this.bdayState = false
      }
      return v
    },
    onBdayContext (ctx) {
      this.bday = ctx.selectedYMD
      this.bdayDisplay = moment(ctx.selectedYMD).format('DD.MM.YYYY')
    }
  }
}
</script>

<style scoped>

</style>
