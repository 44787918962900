<template>
  <b-card
    header="Description"
    :class="descriptionClass"
    class="text-left"
  >
    <b-form-textarea
      ref="input"
      v-model="description"
      :disabled="isLocked"
      @input="onChange"
    />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapComputedFormFields, mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'Description',
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    repo: {
      type: String,
      required: true,
      default: ''
    },
    idx: {
      type: Number,
      default: -1
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      height: 60
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName'
    }),
    ...mapComputedFormFields('customerEdit', [
      'description'
    ]),
    ...mapComputedStateFields('customerEdit', [
      'dirty',
      'editColumnName',
      'editColumnCursor'
    ]),
    itemData () {
      if (this.idx > 0) {
        const value = this.getByName(`${this.repo}/show`, this.idx)
        return value && value.data ? value.data : []
      }
      return false
    },
    descriptionClass () {
      if (this.isDirty('description')) {
        return 'bg-warning'
      }
      return ''
    }
  },
  watch: {
    itemData (n) {
      if (n) {
        this.$forceUpdate()
      }
    }
  },
  mounted () {
    this.setInputState()
    this.calcHeight()
  },
  methods: {
    setInputState () {
      if (this.editColumnName === 'description' && this.$refs.input) {
        this.$refs.input.focus()
        if (this.$refs.input.setSelectionRange && this.editColumnCursor >= 0) {
          this.$refs.input.setSelectionRange(this.editColumnCursor, this.editColumnCursor)
        }
      }
    },
    onChange () {
      this.editColumnName = 'description'
      this.editColumnCursor = (this.$refs.input && this.$refs.input.selectionStart) || -1
      this.$nextTick(() => {
        this.setInputState()
        this.calcHeight()
      })
    },
    isDirty (fieldKey) {
      const dirtyFields = Object.keys(this.dirty)
      if (dirtyFields.length && dirtyFields.includes(fieldKey)) {
        return true
      }
      return false
    },
    calcHeight () {
      if (this.$refs.input?.$el) {
        this.$refs.input.$el.style.height = ''
        const newHeight = this.$refs.input.$el.scrollHeight
        this.$refs.input.$el.style.height = (newHeight > 350 ? 350 : newHeight) + 'px'
      }
    }
  }
}
</script>

<style scoped>

</style>
